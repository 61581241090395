// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@include mat.core();

$exp-default-primary: (
  50: #eceff1,
  100: #cfd8dc,
  200: #b0bec5,
  300: #90a4ae,
  400: #78909c,
  500: #5e7a88,
  600: #546e7a,
  700: #455a64,
  800: #37474f,
  900: #263238,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: rgba(white, 0.87),
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  ),
);

$exp-default-secondary: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #2095f3,
  600: #1e87e5,
  700: #1976d2,
  800: #1564c0,
  900: #0d46a1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette($exp-default-primary, 900, 050, 900);
$app-accent: mat.define-palette($exp-default-secondary, 700, 050, 900);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);
$app-typgraphy: mat.define-typography-config();

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography: $app-typgraphy,
  )
);

@include mat.strong-focus-indicators(
  (
    border-style: solid,
    border-width: 4px,
    border-radius: 2px,
  )
);

@include mat.all-component-themes($app-theme);
@include mat.all-component-typographies($app-typgraphy);
@include mat.strong-focus-indicators-theme($app-theme);

:root {
  /* gaps and radius */

  --exp-border-radius: 4px;

  /* background */
  --exp-background-color: #ffffff;
  --exp-background-color-rgb: 255, 255, 255;
  --exp-background-color-contrast: #000000;

  /* card color */
  --exp-background-card-color: #ffffff;
  --exp-background-card-rgb: 255, 255, 255;
  --exp-background-card-contrast: #000000;

  /* special colors */
  --exp-nav-background-color: #ffffff;
  --exp-nav-background-color-rgb: 255, 255, 255;
  --exp-nav-background-color-contrast: #000000;

  /* text */
  --exp-text-color: #000000;
  --exp-text-color-rgb: 0, 0, 0;

  --fontSizeM: 0.9rem;

  //Keep ion color for unter the hood stuff
  --ion-color-primary: #263238;
  --ion-color-accent: #3880ff;
  --exp-color-primary: #263238;
  --exp-color-primary-rgb: 38, 50, 56;
  --exp-color-primary-contrast: #ffffff;
  --exp-color-primary-contrast-rgb: 255, 255, 255;
  --exp-color-primary-shade: #1e2936;
  --exp-color-primary-tint: #384450;

  --ion-color-secondary: #3b536b;
  --exp-color-secondary: #3b536b;
  --exp-color-secondary-rgb: 59, 83, 107;
  --exp-color-secondary-contrast: #ffffff;
  --exp-color-secondary-contrast-rgb: 255, 255, 255;
  --exp-color-secondary-shade: #34495e;
  --exp-color-secondary-tint: #4f647a;

  /** tertiary **/
  --ion-color-tertiary: #495869;
  --exp-color-tertiary: #495869;
  --exp-color-tertiary-rgb: 73, 88, 105;
  --exp-color-tertiary-contrast: #ffffff;
  --exp-color-tertiary-contrast-rgb: 255, 255, 255;
  --exp-color-tertiary-shade: #34495e;
  --exp-color-tertiary-tint: #4f647a;

  /** success **/
  --exp-color-success: #2dd36f;
  --exp-color-success-rgb: 45, 211, 111;
  --exp-color-success-contrast: #ffffff;
  --exp-color-success-contrast-rgb: 255, 255, 255;
  --exp-color-success-shade: #28ba62;
  --exp-color-success-tint: #42d77d;

  /** warning **/
  --exp-color-warning: #ffc409;
  --exp-color-warning-rgb: 255, 196, 9;
  --exp-color-warning-contrast: #000000;
  --exp-color-warning-contrast-rgb: 0, 0, 0;
  --exp-color-warning-shade: #e0ac08;
  --exp-color-warning-tint: #ffca22;

  /** danger **/
  --exp-color-danger: #f93a3a;
  --exp-color-danger-rgb: 249, 58, 58;
  --exp-color-danger-contrast: #ffffff;
  --exp-color-danger-contrast-rgb: 255, 255, 255;
  --exp-color-danger-shade: #db3333;
  --exp-color-danger-tint: #fa4e4e;

  /** dark **/
  --exp-color-dark: #222428;
  --exp-color-dark-rgb: 34, 36, 40;
  --exp-color-dark-contrast: #ffffff;
  --exp-color-dark-contrast-rgb: 255, 255, 255;
  --exp-color-dark-shade: #1e2023;
  --exp-color-dark-tint: #383a3e;

  /** medium **/
  --exp-color-medium: #92949c;
  --exp-color-medium-rgb: 146, 148, 156;
  --exp-color-medium-contrast: #ffffff;
  --exp-color-medium-contrast-rgb: 255, 255, 255;
  --exp-color-medium-shade: #808289;
  --exp-color-medium-tint: #b9bbc4;

  /** light **/
  --exp-color-light: #f4f5f8;
  --exp-color-light-rgb: 244, 245, 248;
  --exp-color-light-contrast: #000000;
  --exp-color-light-contrast-rgb: 0, 0, 0;
  --exp-color-light-shade: #d7d8da;
  --exp-color-light-tint: #f5f6f9;
}
