/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@use './global-colors.theme.scss' as colors;
@import './variables.scss';

/* Initiate the process of locally importing the required font and icons from google Api */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/materialIcons/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/materialIcons/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDCvHOej.woff2)
    format('woff2');
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}

// fonts
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../assets/fonts/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2/) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../assets/fonts/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2/) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../assets/fonts/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2/) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../assets/fonts/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2/) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../assets/fonts/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2/) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../assets/fonts/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2/) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../assets/fonts/KFOlCnqEu92Fr1MmSU5fBBc4.woff2/) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/KFOmCnqEu92Fr1Mu72xKOzY.woff2/) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/KFOmCnqEu92Fr1Mu5mxKOzY.woff2/) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/KFOmCnqEu92Fr1Mu7mxKOzY.woff2/) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/KFOmCnqEu92Fr1Mu4WxKOzY.woff2/) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/KFOmCnqEu92Fr1Mu7WxKOzY.woff2/) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/KFOmCnqEu92Fr1Mu7GxKOzY.woff2/) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/KFOmCnqEu92Fr1Mu4mxK.woff2/) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../assets/fonts/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2/) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../assets/fonts/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2/) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../assets/fonts/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2/) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../assets/fonts/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2/) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../assets/fonts/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2/) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../assets/fonts/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2/) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../assets/fonts/KFOlCnqEu92Fr1MmEU9fBBc4.woff2/) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* End of locally importing */

*,
::before,
::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  background: var(--exp-background-color) !important;
  color: var(--exp-background-color-contrast) !important;
  position: unset !important;
  overflow: visible;
  margin: 0;
  font-family: 'Roboto';

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
  text-size-adjust: none;
}

// Mat Dialog styling of image preview Dialog
.preview-image-container .mat-mdc-dialog-container {
  padding: 0;
}
.mat-mdc-dialog-container {
  overflow: hidden !important;
}

/* --------------------------- global classes -------------------------------- */

.error-message-container {
  display: flex;
  justify-content: center;
  @include colors.warn-text-color($app-theme);
}

/* -------------- overview component -------------------------- */

// some styling needs to be done in the global.scss because of selector hierarchy

.mat-expansion-panel-body {
  padding: 0px !important;

  .mat-mdc-list-item {
    height: initial !important;
    min-height: 48px !important;
  }

  .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
    margin-right: 0px;
  }
}

.mat-expansion-panel-header {
  padding: 0 1rem !important;
}
.mat-expansion-panel-header.hierarchy-panel-header {
  padding: 0 0.5rem !important;
}
.mat-expansion-panel-header.mat-expanded {
  height: 48px !important;
}

.mat-expansion-panel-content {
  max-height: 250px;
  overflow: auto !important;
  font-size: 0.75rem;
}

.mat-accordion .mat-expansion-panel:first-of-type {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

/*  -------------- tables -------------------------------- */

mat-mdc-header-cell {
  background-color: rgba($color: #000000, $alpha: 0.08);
}
/*  -------------- generic form -------------------------------- */
.form-container {
  .mat-mdc-form-field-infix {
    width: auto !important;
  }
}

mat-paginator {
  .mat-mdc-form-field-flex {
    height: unset;
  }
}

/*  -------------- Dialogs -------------------------------- */

.dialog-container {
  height: 100%;
}

.dialog-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  h2 {
    margin: 0rem;
    margin-top: 10px !important;
  }

  .close-button {
    padding-right: 12px;
    padding-top: 9px;
  }
}

.dialog-header + .dialog-content {
  padding-top: 0px !important;
}

.dialog-content {
  width: 100%;
}

.dialog-actions {
  display: flex;
  margin-top: auto;
  justify-content: flex-end !important;
  position: sticky;
  gap: 8px;
}

/* -------------------------- flex box ------------------------------------- */

.flex-row {
  display: flex;
}

.flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-row-verticaly {
  align-items: center;
}

/* -------------------------- gaps --------------------------- */

.maring-s {
  margin: 0.5rem;
}

.flex-gap-s {
  gap: 0.5rem;
}
.flex-gap-b {
  gap: 1rem;
}
