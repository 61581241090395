@use 'sass:map';
@use '@angular/material' as mat;

@mixin bg-warn($theme) {
  $color-config: mat.get-color-config($theme);
  $warn-palette: map.get($color-config, 'warn');

  background-color: mat.get-color-from-palette($warn-palette, 500);
}

@mixin warn-text-color($theme) {
  $color-config: mat.get-color-config($theme);
  $warn-palette: map.get($color-config, 'warn');

  color: mat.get-color-from-palette($warn-palette, 500);
}

@mixin primary-bg-color($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  background-color: mat.get-color-from-palette($primary-palette, 600);
  color: mat.get-color-from-palette($primary-palette, '600-contrast');
}

@mixin primary-color-600-contrast($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  color: mat.get-color-from-palette($primary-palette, '600-contrast');
}

@mixin primary-color-600($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  color: mat.get-color-from-palette($primary-palette, 600);
}

@mixin primary-bg-color-100($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  background-color: mat.get-color-from-palette($primary-palette, 100);
}

@mixin primary-bg-color-400($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  background-color: mat.get-color-from-palette($primary-palette, 400);
}
